import React from 'react';
import {
  NavbarContainer,
  NavbarWrap,
  NavbarLogo,
  MobileMenu,
  Hamburger,
  Close,
  NavMenu,
  NavLink,
  NavItem
} from './header.style';
import { animateScroll as scroll } from 'react-scroll';

import { FaBars, FaTimes } from 'react-icons/fa';

const Header = ({ sideBar, setSideBar }) => {
  const links = [
    { name: 'home', path: 'home' },
    { name: 'skills', path: 'skills' },
    { name: 'contact me', path: 'contact' }
  ];

  return (
    <NavbarContainer>
      <NavbarWrap>
        <NavbarLogo onClick={() => scroll.scrollToTop()}>Seun Oye</NavbarLogo>
        <MobileMenu onClick={() => setSideBar(!sideBar)}>
          <Hamburger sideBar={sideBar}>
            <FaBars />
          </Hamburger>
          <Close sideBar={sideBar}>
            <FaTimes />
          </Close>
        </MobileMenu>
        <NavMenu>
          {links.map((link, i) => {
            return (
              <NavItem key={i}>
                <NavLink
                  to={link.path}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {link.name}
                </NavLink>
              </NavItem>
            );
          })}
        </NavMenu>
      </NavbarWrap>
    </NavbarContainer>
  );
};

export default Header;
