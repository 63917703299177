import { React } from 'react';

import { SoftSkillsContainer } from './soft-skills.style';

import Planet from './Planet';
import Rocket from './Rocket';
import Cubes from './Cubes';

const SoftSkills = () => {
  return (
    <SoftSkillsContainer>
      <Rocket />
      <Planet />
      <Cubes />
    </SoftSkillsContainer>
  );
};

export default SoftSkills;
