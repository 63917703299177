import styled from 'styled-components';

const primary = props => props.theme.primary;
const bg = props => props.theme.bg;
const bg2 = props => props.theme.bg2;

export const HeroContainer = styled.div`
  background: ${primary};

  min-height: 500px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;

  position: relative;
  z-index: 1;
`;

export const HeroWrap = styled.div`
  display: flex;

  height: 100%;
  max-width: 1200px;
  width: 100%;

  margin: 0 auto;
  padding: 0 32px;

  &:before {
    content: '';
    z-index: 2;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    left: 0;
    background: linear-gradient(
      to left,
      transparent 10%,
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.9)
    );
  }
`;

export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;

  height: 100%;

  overflow: hidden;
`;

export const VideoBg = styled.video`
  width: 100%;

  height: 100%;

  -o-object-fit: cover;
  object-fit: cover;
`;

export const HeroContent = styled.div`
  position: relative;
  left: 0;
  z-index: 3;

  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: flex-start;

  margin: 10rem 0;
`;

export const TextWrap = styled.div`
  pointer-events: none;

  margin-bottom: 24px;
`;

export const HeroH1 = styled.h1`
  color: ${bg};
  margin-bottom: 16px;
`;

export const HeroP = styled.p`
  color: ${bg2};
  max-width: 300px;
`;
