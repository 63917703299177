import React from 'react';

import { SiUnity, SiGimp, SiCsharp, SiBlender } from 'react-icons/si';

import {
  HardSkillsContainer,
  HardSkillsWrap,
  HardSkillsLeft,
  HardSkillsRight,
  HardSkillsList,
  HardSkill,
  HardSkillIconWrap,
  HardSkillsText,
  HardSkillsTitle,
  HardSkillsH2,
  HardSkillsP,
  ArrowIcon,
} from './hard-skills.style';

const HardSkills = () => {
  return (
    <HardSkillsContainer>
      <HardSkillsWrap>
        <HardSkillsLeft>
          <HardSkillsList>
            <HardSkill
              col="1/2"
              row="1/2"
              boxShadow={`-10px -3px 3px 0px rgba(0, 0, 0, 0.7)`}
              hoverShadow={`-20px -6px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 0px rgba(0, 0, 0, 0.5)`}
              iconHoverColor="#000"
            >
              <HardSkillIconWrap>
                <SiUnity />
              </HardSkillIconWrap>
            </HardSkill>
            <HardSkill
              col="2/3"
              row="1/2"
              boxShadow={`-5px -5px 3px 0px rgba(0, 0, 0, 0.7)`}
              hoverShadow={`-10px -10px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 0px rgba(0, 0, 0, 0.5)`}
              iconHoverColor="#651471"
            >
              <HardSkillIconWrap>
                <SiCsharp />
              </HardSkillIconWrap>
            </HardSkill>
            <HardSkill
              col="2/3"
              row="2/3"
              boxShadow={`-10px 3px 3px 0px rgba(0, 0, 0, 0.7)`}
              hoverShadow={`-20px 6px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 0px rgba(0, 0, 0, 0.5)`}
              iconHoverColor="#4F4839"
            >
              <HardSkillIconWrap>
                <SiGimp />
              </HardSkillIconWrap>
            </HardSkill>
            <HardSkill
              col="3/4"
              row="2/3"
              boxShadow={`-5px 5px 3px 0px rgba(0, 0, 0, 0.7)`}
              hoverShadow={`-10px 10px 10px 3px rgba(0, 0, 0, 0.5), 0px 0px 5px 0px rgba(0, 0, 0, 0.5)`}
              iconHoverColor="#DE7000"
            >
              <HardSkillIconWrap>
                <SiBlender />
              </HardSkillIconWrap>
            </HardSkill>
          </HardSkillsList>
        </HardSkillsLeft>
        <HardSkillsRight>
          <HardSkillsText>
            <HardSkillsTitle>
              <ArrowIcon />
              &nbsp; My Skills
            </HardSkillsTitle>
            <HardSkillsH2>Why hire me?</HardSkillsH2>
            <HardSkillsP>
              I have mastered the use of industry standard software such as
              unity, blender and others used in the production pipeline of
              several hit titles.
            </HardSkillsP>
          </HardSkillsText>
        </HardSkillsRight>
      </HardSkillsWrap>
    </HardSkillsContainer>
  );
};

export default HardSkills;
