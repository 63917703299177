import React from 'react';
import HardSkills from './HardSkills/HardSkills';
import SoftSkills from './SoftSkills/SoftSkills';

import styled from 'styled-components';

const SkillsContainer = styled.div`
  width: 100%;
`;

const Skills = () => {
  return (
    <SkillsContainer id="skills">
      <HardSkills />
      <SoftSkills />
    </SkillsContainer>
  );
};

export default Skills;
