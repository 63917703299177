import styled from 'styled-components';

const typeface = (props) => props.theme.typeface;
const accent = (props) => props.theme.accent;

export const ContactContainer = styled.div`
  background-color: #fff;

  width: 100%;
`;

export const ContactWrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 64px 32px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContactBox = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;

`;

export const ContactBoxTop = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 999px) {
    padding-top: 16px;
  }
`;

export const ContactBoxBottom = styled.div`
  width: 100%;
  padding: 64px;
  padding-top: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 480px) {
    padding: 0 24px 48px 24px;
    padding-top: 0;
  }
`;

export const ContactCanvas = styled.div`
  width: 100%;
  height: 100%;

  @media screen and (max-width: 960px) {
    max-width: 300px;
  }
`;

export const ContactPhoto = styled.img`
  border-radius: 50%;
  width: 150px;
  height: auto;
  margin: 48px 0 24px 0;
`;

export const ContactTitle = styled.h2`
  margin-bottom: 40px;
`;

export const ContactButton = styled.a`
  background-color: ${accent};
  padding: 16px;

  text-decoration: none;
  text-transform: capitalize;
  border-radius: 3px;

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

export const ContactButtonText = styled.p`
  text-align: center;
  color: ${typeface};
`;
