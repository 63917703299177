import styled from 'styled-components';
const primary = (props) => props.theme.primary;

export const FooterContainer = styled.div`
  width: 100%;

  background: #fff;
  color: ${primary};
`;

export const FooterWrap = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FooterSocialMenu = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
  width: 100%;
  height: 100%;
`;

export const FooterSocialItem = styled.li`
  font-size: 2rem;
  list-style: none;
`;

export const FooterSocialLink = styled.a`
  text-decoration: none;
  color: ${primary};

  padding: 20px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
