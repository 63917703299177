import styled from 'styled-components';
import { FaArrowAltCircleLeft } from 'react-icons/fa';

const primary = props => props.theme.primary;
const bg = props => props.theme.bg;
const bg2 = props => props.theme.bg2;
const typeface = props => props.theme.typeface;

export const HardSkillsContainer = styled.div`
  background-color: ${bg2};
  color: ${typeface};

  width: 100%;
`;

export const HardSkillsWrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 128px 32px;

  display: flex;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const HardSkillsLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 32px 0;

  @media screen and (max-width: 480px) {
    margin-bottom: 32px;
  }
`;

export const HardSkillsList = styled.div`
  display: grid;
  grid-gap: 16px;

  transform: rotateZ(45deg);

  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
  }
`;

export const HardSkill = styled.div`
  background-color: ${bg};
  color: ${primary};

  grid-column: ${({ col }) => (col ? col : 'unset')};
  grid-row: ${({ row }) => (row ? row : 'unset')};

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 12px;
  border-radius: 20px;

  box-shadow: 8px 8px 2px 0px rgba(88, 88, 88, 1);

  transition: ease-in-out 150ms;

  &:hover {
    transform: translate(5px, 5px);
    box-shadow: 3px 3px 2px 0px rgba(88, 88, 88, 1);

    color: ${props => props.iconHoverColor || 'black'};
  }
`;

export const HardSkillIconWrap = styled.div`
  display: flex;
  justify-content: center;

  padding: 16px;

  font-size: 2rem;

  border-radius: 50%;

  transform: rotateZ(-45deg);

  @media screen and (max-width: 480px) {
    margin-bottom: 0;
    border: none;
  }
`;

export const HardSkillName = styled.h2`
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const HardSkillsRight = styled.div`
  flex: 2;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 768px) {
    align-items: stretch;
  }
`;

export const HardSkillsText = styled.div`
  margin-top: -128px;
  margin-left: 64px;

  @media screen and (max-width: 768px) {
    margin: 32px 0;
  }
`;

export const HardSkillsTitle = styled.h3`
  font-weight: 300;
  display: flex;
  align-items: center;

  color: ${primary};
`;

export const HardSkillsH2 = styled.h2``;

export const HardSkillsP = styled.p`
  max-width: 400px;

  @media screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

export const ArrowIcon = styled(FaArrowAltCircleLeft)`
  @media screen and (max-width: 768px) {
    transform: rotate(90deg);
  }
`;
