import styled from 'styled-components';
const typeface = (props) => props.theme.typeface;
const accent = (props) => props.theme.accent;

const Link = ({ className, children, href }) => (
  <a href={href} className={className}>
    <p>{children}</p>
  </a>
);
export const ButtonNormal = styled(Link)`
  background-color: ${({ bgColor }) => (bgColor ? bgColor : accent)};
  color: ${({ color }) => (color ? color : typeface)};

  padding: 16px 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: none;
  text-transform: capitalize;

  border-radius: 10px;

  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1),
    -1px -1px 10px 0px rgba(0, 0, 0, 0.1);

  transition: ease-in-out 100ms;

  &:hover {
    cursor: pointer;
    background-color: #ebb700;
  }

  &:active {
    background-color: ${accent};
    box-shadow: none;
  }

  @media screen and (max-width: 480px) {
    align-self: stretch;
  }
`;

