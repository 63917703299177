const theme = {
  // --green-blue
  // primary: '#306bac',
  // --sea-lime
  primary: '#abc0bb',

  // --mikado-yellow
  accent: '#ffc600',

  // --gainsboro
  // bg2: '#dddddd',
  bg2: '#eeeeee',

  // --eerie-black
  typeface: '#222222',

  // --cultured
  // bg: '#f0f0f0',
  bg: '#ffffff',
};

export default theme;
