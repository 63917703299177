export const vertexshader = `
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader
// uniform mat4 modelMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 viewMatrix;
// uniform mat3 normalMatrix;

// Default uniforms provided by ShaderFrog.
// uniform vec3 cameraPosition;
// uniform float time;

// Default attributes provided by THREE.js. Attributes are only available in the
// vertex shader. You can pass them to the fragment shader using varyings
// attribute vec3 position;
// attribute vec3 normal;
// attribute vec2 uv;
// attribute vec2 uv2;

// Examples of variables passed from vertex to fragment shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;

varying mat4 modelmatrix;


//precision highp float;
//attribute vec3 position;
//attribute vec3 normal;
//uniform mat3 normalMatrix;
//uniform mat4 modelViewMatrix;
//uniform mat4 projectionMatrix;

//....................normal edit...........................................
//uniform mat3 p3d_NormalMatrix;
//attribute vec3 normal;
//attribute vec3 binormal;
//in vec3 p3d_Tangent;
attribute vec4 tangent;
varying vec3 kvecnormal;
varying vec3 kbinormal;
varying vec3 ktangent;
varying vec3 vll;
uniform vec3 lightPosition;
//.........................................................................

varying vec3 fNormal;
varying vec3 fPosition;

void main()
{
    // To pass variables to the fragment shader, you assign them here in the
    // main function. Traditionally you name the varying with vAttributeName
    vNormal = normal;
    vUv = uv;
    //vUv2 = uv2;
    vPosition = position;
    
    // This sets the position of the vertex in 3d space. The correct math is
    // provided below to take into account camera and object data.
    //gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );


    fNormal = normalMatrix  *  normal; 
    vec4 pos = modelViewMatrix * vec4(position, 1.0);
    fPosition = pos.xyz;
    gl_Position = projectionMatrix * pos;
    
    
    //.....................normal edit...............................................
    kvecnormal = normalize(normalMatrix * normal);
    ktangent = normalize(normalMatrix * tangent.xyz);
    kbinormal = normalize(cross(kvecnormal,ktangent) * tangent.w);
    
    vec4 vworldpos = modelMatrix * vec4(position, 1.0);
    vll = normalize(normalMatrix*(lightPosition-vworldpos.xyz));
    //.................................................................................

    modelmatrix = modelMatrix;
}`;

export const fragmentshader = `
//#extension GL_OES_standard_derivatives : enable

/**
* Example Fragment Shader
* Sets the color and alpha of the pixel by setting gl_FragColor
*/

//#extension GL_OES_standard_derivatives : enable

// Set the precision for data types used in this shader
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader
// uniform mat4 modelMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 viewMatrix;
// uniform mat3 normalMatrix;

varying mat4 modelmatrix;

// Default uniforms provided by ShaderFrog.
// uniform vec3 cameraPosition;
// uniform float time;

// A uniform unique to this shader. You can modify it to the using the form
// below the shader preview. Any uniform you add is automatically given a form
uniform sampler2D uColorMap;
uniform sampler2D uNormalMap;
uniform float normalintensity;

uniform vec3 lightPosition;
uniform float lightintensity;


// Example varyings passed from the vertex shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;

//環境設定
float brightness;
vec3 worldPosition;
vec3 worldNormal;
vec3 lightVector;
vec3 viewVector;
vec3 halfVector;

vec4 viewNormal;
vec3 viewDirs;

float NdotV;
float NdotL;
float NdotH;

float u_gloss=0.5;
vec3 specularColor = vec3(1.0);

struct ReflectedLight {
	vec3 directDiffuse;
	vec3 directSpecular;
	vec3 indirectDiffuse;
	vec3 indirectSpecular;
};

struct PhysicalMaterial {
	vec3 diffuseColor;
	float specularRoughness;
	vec3 specularColor;
/*#ifdef CLEARCOAT
	float clearcoat;
	float clearcoatRoughness;
#endif
#ifdef USE_SHEEN
	vec3 sheenColor;
#endif*/
};

ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
void AOMap()
{
    
}

void SetSceneStatus()
{
    worldPosition = ( modelmatrix * vec4( vPosition, 1.0 )).xyz;
    worldNormal = normalize( vec3( modelmatrix * vec4( vNormal, 0.0 ) ) );
    lightVector = lightintensity*normalize( lightPosition - worldPosition );
    viewVector = normalize(cameraPosition - worldNormal);
    halfVector = normalize(lightPosition + viewVector);
    
    viewNormal = viewMatrix * vec4(vNormal, 1.0 );
    viewDirs = normalize(vec3(cameraPosition.xyz-viewNormal.xyz));
    
    
    NdotV = normalize(dot(viewDirs,normalize(cameraPosition)));
    NdotL = dot(lightPosition,worldNormal);//光線與法線的內積角度,計算陰暗面
    NdotH = dot(worldNormal, normalize(viewVector+lightPosition)) ;
    
}

//....................normal edit...........................................
varying vec3 kvecnormal;
varying vec3 kbinormal;
varying vec3 ktangent;
varying vec3 vll;
//.........................................................................

vec4 BaseLight()
{
    vec3 textnorm = texture2D(uNormalMap, vUv).rgb;
    textnorm = normalize(textnorm * 2.0 - 1.0);
    mat3 TBN = mat3(ktangent, kbinormal, kvecnormal);
    textnorm = normalize(TBN * textnorm);
    
    float mid = clamp(dot( worldNormal, lightVector ),-1.0,1.0);
    brightness = clamp(dot( textnorm, vll ),-1.0,1.0)/**normalintensity*mid*/;
    
    vec4 result = vec4( vec3(brightness), 1.0 );
    //vec4 result2 = vec4(TBN[0], 1.0);
    
    // return vec4(vec3(mid),1.0);
    return result;
}

vec4 SpecularMapping()
{
     //各個法線光線強度計算(做反射計算用)
    float lightIntensity = 0.0;
	if(NdotL<=0.0)
	{
		lightIntensity = 0.0;
	}
	else
	{
		lightIntensity = 1.0;
	}
	
	float SpecularPower =  exp2( (u_gloss*10.)+1.0);
    //u_gloss = exp2(((u_gloss)*10.)+1.);
    float specularIntensity = pow((lightIntensity * NdotH), SpecularPower);
	vec4  specular = vec4( clamp(vec3(specularIntensity)*specularColor ,0.0,1.0) ,1.0) ;
	
	return specular;
}

precision highp float;

uniform vec3 kecolor;
uniform float start;
uniform float end;
uniform float kealpha;

varying vec3 fPosition;
varying vec3 fNormal;



void main()
{
    //...............................fresnel...........................................................
    vec3 kenormal = normalize(fNormal);
    vec3 eye = normalize(-fPosition.xyz);
    float rim = smoothstep(start, end, 1.0 - dot(kenormal, eye));
    //gl_FragColor = vec4( clamp(rim, 0.0, 1.0) * kealpha * kecolor, 1.0 ); 
    //..................................................................................................
    
  
    SetSceneStatus();

    //vec4 result; = texture2D(uColorMap, vUv);
    vec4 baseColor = BaseLight();
    
    //gl_FragColor =  baseColor;
    
    
    gl_FragColor =  baseColor+(vec4(vec3((dot(viewDirs,worldNormal))),1.0)*texture2D(uColorMap, vUv)
                    +vec4(vec3(1.0-(dot(viewDirs,worldNormal))),1.0)*texture2D(uColorMap, vUv)*vec4(1.0,1.0,1.0,1.0))
                    +vec4( clamp(rim, 0.0, 1.0) * kealpha * kecolor, 1.0 ) /*+ vec4(vec3(dot(vec4(lightVector, 1.0),texture2D(uNormalMap, vUv))),1.0)*/; 
                    //+vec4(vec3(1.-NdotH),1.0)*texture2D(uNormalMap, vUv);//result;//vec4(result.rgb*smoothstep(0.0,1.0,brightness),1.0);
    // gl_FragColor = vec4(vec3(normalSpecular),1.0);
    //gl_FragColor = vec4(result.rgb,1.0);
}

//`;
//export default vertexshader

export const vert2 = `
void main()
{
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const frag2 = `
uniform vec3 color;
void main() 
{
    gl_FragColor = vec4(color, 1.0);
}
`;

export const vert3 = `
precision highp float;
precision highp int;


// Examples of variables passed from vertex to fragment shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;

varying mat4 modelmatrix;


//....................normal edit...........................................
attribute vec3 tangent;
attribute vec3 bitangent;
varying vec3 kvecnormal;
varying vec3 kbinormal;
varying vec3 ktangent;
varying vec3 vll;
uniform vec3 lightPosition;
//.........................................................................

varying vec3 fNormal;
varying vec3 fPosition;

void main()
{
    // To pass variables to the fragment shader, you assign them here in the
    // main function. Traditionally you name the varying with vAttributeName
    vNormal = normal;
    vUv = uv;
    //vUv2 = uv2;
    vPosition = position;
    
    // This sets the position of the vertex in 3d space. The correct math is
    // provided below to take into account camera and object data.
    //gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );


    fNormal = normalMatrix  *  normal; 
    vec4 pos = modelViewMatrix * vec4(position, 1.0);
    fPosition = pos.xyz;
    gl_Position = projectionMatrix * pos;
    
    
    //.....................normal edit...............................................
    kvecnormal = normalize(normalMatrix * normal);
    ktangent = tangent;
    kbinormal = bitangent;
    
    vec4 vworldpos = modelMatrix * vec4(position, 1.0);
    vll = normalize(normalMatrix*(lightPosition-vworldpos.xyz));
    //.................................................................................

    modelmatrix = modelMatrix;
}`;

export const frag4 = `

//#extension GL_OES_standard_derivatives : enable

/**
* Example Fragment Shader
* Sets the color and alpha of the pixel by setting gl_FragColor
*/

// Set the precision for data types used in this shader
precision highp float;
precision highp int;

//Default THREE.js uniforms available to both fragment and vertex shader
// uniform mat4 modelMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 viewMatrix;
// uniform mat3 normalMatrix;

//Default uniforms provided by ShaderFrog.
// uniform vec3 cameraPosition;
// uniform float time;

// A uniform unique to this shader. You can modify it to the using the form
// below the shader preview. Any uniform you add is automatically given a form
uniform sampler2D uColorMap;
uniform sampler2D uNormalMap;
uniform float normalintensity;

uniform vec3 lightPosition;
uniform float lightintensity;


// Example varyings passed from the vertex shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;

//環境設定
float brightness;
vec3 worldPosition;
vec3 worldNormal;
vec3 lightVector;
vec3 viewVector;
vec3 halfVector;

vec4 viewNormal;
vec3 viewDirs;

float NdotV;
float NdotL;
float NdotH;

float u_gloss=0.5;
vec3 specularColor = vec3(1.0);

struct ReflectedLight {
	vec3 directDiffuse;
	vec3 directSpecular;
	vec3 indirectDiffuse;
	vec3 indirectSpecular;
};

struct PhysicalMaterial {
	vec3 diffuseColor;
	float specularRoughness;
	vec3 specularColor;
/*#ifdef CLEARCOAT
	float clearcoat;
	float clearcoatRoughness;
#endif
#ifdef USE_SHEEN
	vec3 sheenColor;
#endif*/
};

ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
void AOMap()
{
    
}

varying mat4 modelmatrix;

void SetSceneStatus()
{
    worldPosition = ( modelmatrix * vec4( vPosition, 1.0 )).xyz;
    worldNormal = normalize( vec3( modelmatrix * vec4( vNormal, 0.0 ) ) );
    lightVector = lightintensity*normalize( lightPosition - worldPosition );
    viewVector = normalize(cameraPosition - worldNormal);
    halfVector = normalize(lightPosition + viewVector);
    
    viewNormal = viewMatrix * vec4(vNormal, 1.0 );
    viewDirs = normalize(vec3(cameraPosition.xyz-viewNormal.xyz));
    
    
    NdotV = normalize(dot(viewDirs,normalize(cameraPosition)));
    NdotL = dot(lightPosition,worldNormal);//光線與法線的內積角度,計算陰暗面
    NdotH = dot(worldNormal, normalize(viewVector+lightPosition)) ;
    
}

//....................normal edit...........................................
varying vec3 kvecnormal;
varying vec3 kbinormal;
varying vec3 ktangent;
varying vec3 vll;
//.........................................................................
mat3 cotangent_frame( vec3 N, vec3 p, vec2 uv )
{
    // get edge vectors of the pixel triangle
    vec3 dp1 = dFdx( p );
    vec3 dp2 = dFdy( p );
    vec2 duv1 = dFdx( uv );
    vec2 duv2 = dFdy( uv );

    // solve the linear system
    vec3 dp2perp = cross( dp2, N );
    vec3 dp1perp = cross( N, dp1 );
    vec3 T = dp2perp * duv1.x + dp1perp * duv2.x;
    vec3 B = dp2perp * duv1.y + dp1perp * duv2.y;

    // construct a scale-invariant frame 
    float invmax = inversesqrt( max( dot(T,T), dot(B,B) ) );
    return mat3( T * invmax, B * invmax, N );
}

vec3 textnorm;

vec4 BaseLight()
{
    textnorm = texture2D(uNormalMap, vUv).rgb;
    textnorm = normalize(textnorm * 2.0 - 1.0);
    //mat3 TBN = mat3(ktangent, kbinormal, kvecnormal);
    mat3 TBN = cotangent_frame(worldNormal, vPosition, vUv);
    textnorm = normalize(TBN * textnorm);
    
    float mid = clamp(dot( worldNormal, lightVector ),-1.0,1.0);
    brightness = clamp((dot( textnorm * 2.0, lightVector)+0.7)/4.0,-1.0,1.2)/**normalintensity*mid*/;
    
    vec4 result = vec4( vec3(brightness), 1.0 );
    //vec4 result2 = vec4(TBN[0], 1.0);
    
    // return vec4(vec3(mid),1.0);
    return result;
}

vec4 SpecularMapping()
{
    vec4 baselight = BaseLight();
    float atten = 3.0;
    float shine = 8.5;
    float lightIntensity = 0.0;
    float bright = 9.0;
    
    vec4 diffusereflection = clamp((baselight*texture2D(uColorMap, vUv)*0.4)*bright ,0.05,1.0);
    //vec4 speculareflection = atten * baselight * pow(dot(reflect(-lightVector, worldNormal), viewDirs), shine);
    vec4 speculareflection = clamp(baselight * atten * vec4(vec3(pow(clamp(dot(reflect(lightVector, textnorm), viewVector),0.0,1.0), shine)),1.0), 0.0,1.0);
    
	return diffusereflection+speculareflection;
}


uniform vec3 kecolor;
uniform float start;
uniform float end;
uniform float kealpha;

varying vec3 fPosition;
varying vec3 fNormal;



void main()
{
    //...............................fresnel...........................................................
    vec3 kenormal = normalize(fNormal);
    vec3 eye = normalize(-fPosition.xyz);
    float rim = smoothstep(start, end, 1.0 - dot(kenormal, eye));
    //gl_FragColor = vec4( clamp(rim, 0.0, 1.0) * kealpha * kecolor, 1.0 ); 
    //..................................................................................................
    
  
    SetSceneStatus();

    //vec4 result; = texture2D(uColorMap, vUv);
    vec4 baseColor = SpecularMapping();
    
    // gl_FragColor =  baseColor;
    // float bright = 9.0;
    
    gl_FragColor =  baseColor + (texture2D(uColorMap, vUv) * vec4(1.0,1.0,1.0,1.0) * 0.07)
                    +vec4(clamp((dot(worldNormal, lightVector)+0.3)*2.0,0.0,1.0)*(clamp(rim, 0.0, 1.0) * kealpha * kecolor), 1.0 ) /*+ vec4(vec3(dot(vec4(lightVector, 1.0),texture2D(uNormalMap, vUv))),1.0)*/; 
                    //+vec4(vec3(1.-NdotH),1.0)*texture2D(uNormalMap, vUv);//result;//vec4(result.rgb*smoothstep(0.0,1.0,brightness),1.0);
    // gl_FragColor = vec4(vec3(normalSpecular),1.0);
    //gl_FragColor = vec4(result.rgb,1.0);
}`;

export const cubevert = `
precision highp float;
precision highp int;


// Examples of variables passed from vertex to fragment shader
// varying vec3 vPosition;
// varying vec3 vNormal;
// varying vec2 vUv;

varying vec3 fNormal;
varying vec3 fPosition;

void main()
{
    // // To pass variables to the fragment shader, you assign them here in the
    // // main function. Traditionally you name the varying with vAttributeName
    // vNormal = normal;
    // vUv = uv;
    // vPosition = position;


    fNormal = normalMatrix  *  normal; 
    vec4 pos = modelViewMatrix * vec4(position, 1.0);
    fPosition = pos.xyz;
    gl_Position = projectionMatrix * pos;
    
}`;

export const cubefrag = `
//#extension GL_OES_standard_derivatives : enable

// Set the precision for data types used in this shader
precision highp float;
precision highp int;



// Example varyings passed from the vertex shader
// varying vec3 vPosition;
// varying vec3 vNormal;
// varying vec2 vUv;

uniform vec3 basecolor;
uniform vec3 kecolor;
uniform float start;
uniform float end;
uniform float kealpha;

varying vec3 fPosition;
varying vec3 fNormal;



void main()
{
    //...............................fresnel...........................................................
    vec3 kenormal = normalize(fNormal);
    vec3 eye = normalize(-fPosition.xyz);
    float rim = smoothstep(start, end, 1.0 - dot(kenormal, eye));
    gl_FragColor = vec4(basecolor, 1.0) + vec4( clamp(rim, 0.0, 1.0) * kealpha * kecolor, 1.0 ); 
    //...................................................................................................
}`;

export const vert0 = `
// Set the precision for data types used in this shader
precision highp float;
precision highp int; 


varying vec2 vUv;
varying vec3 vPosition;
varying mat4 modelmatrix;

void main()
{
    vUv = uv;
    modelmatrix = modelMatrix;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const frag0 = `
// Set the precision for data types used in this shader
precision highp float;
precision highp int;

uniform vec2 mousepos;
uniform sampler2D map1;
uniform sampler2D map2;
uniform sampler2D map3;
uniform sampler2D map4;
uniform vec3 circleCentre;
uniform float cutoutRadius;

// Example varyings passed from the vertex shader
varying vec3 vPosition;
varying mat4 modelmatrix;
varying vec2 vUv;

vec2 mpos;
vec2 newuv;
vec2 enuv;

void main() 
{
    newuv = vec2(1.0-vUv.x, vUv.y);
    enuv =  vec2(newuv.x/2.0+0.2, newuv.y);


    mpos = (mousepos * -1.0);
    vec4[] textures = vec4[4](texture2D(map1, newuv+(mpos*0.1)), texture2D(map2, newuv+(mpos*0.15)), texture2D(map3, newuv+(mpos*0.2)), texture2D(map4, newuv+(mpos*0.25)));


    vec4 outvec;
    for(int i = 3; i >= 0; i -= 1)
    {
        if (textures[i].w > 0.9)
        {
            outvec = textures[i];
        }
    }

    vec3 worldPosition = ( modelmatrix * vec4( vPosition, 1.0 )).xyz;
    if(length(worldPosition - circleCentre) > cutoutRadius)
    {
        outvec = vec4(1.0,1.0,1.0,1.0);
    }

    gl_FragColor = outvec; //vec4((texture1.xyz * texture1.w) + (texture2.xyz * texture2.w) + (texture3.xyz * texture3.w), 1.0);
}`;

export const procPlanetVert = `
precision highp float;
precision highp int;


// Examples of variables passed from vertex to fragment shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;
varying mat4 modelmatrix;

// Default attributes provided by THREE.js. Attributes are only available in the
// vertex shader. You can pass them to the fragment shader using varyings
// attribute vec3 position;
// attribute vec3 normal;
// attribute vec2 uv;
// attribute vec2 uv2;


// uniform mat3 normalMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 modelMatrix;

varying vec3 fNormal;
varying vec3 fPosition;

void main()
{
    // To pass variables to the fragment shader, you assign them here in the
    // main function. Traditionally you name the varying with vAttributeName
    vNormal = normal;
    vUv = uv;
    // vUv2 = uv2;
    vPosition = position;
    


    //fNormal = normalMatrix  *  normal; 
    fNormal = normalMatrix  *  vNormal;
    vec4 pos = modelViewMatrix * vec4(position, 1.0);
    fPosition = pos.xyz;
    gl_Position = projectionMatrix * pos;

    modelmatrix = modelMatrix;
}`;

export const proPlanetFrag = `
// #extension GL_OES_standard_derivatives : enable

// Set the precision for data types used in this shader
precision highp float;
precision highp int;

// A uniform unique to this shader. You can modify it to the using the form
// below the shader preview. Any uniform you add is automatically given a form
uniform float normalintensity;
uniform vec3 lightPosition;
uniform float lightintensity;
// uniforms for the fresnel / edge lighting effect;
uniform vec3 kecolor;
uniform float start;
uniform float end;
uniform float kealpha;

uniform vec3 waterColor;
uniform vec3 landBorderColor;
uniform vec3 lowLandColor;
uniform vec3 midLandColor;
uniform vec3 highLandColor;
uniform vec3 offset;

// Default uniforms provided by ShaderFrog.
// uniform vec3 cameraPosition;
// uniform float time;

// Default THREE.js uniforms available to both fragment and vertex shader
// uniform mat4 modelMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 viewMatrix;
// uniform mat3 normalMatrix;

// Example varyings passed from the vertex shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;

// Personally added varyings
varying mat4 modelmatrix;
varying vec3 fPosition;
varying vec3 fNormal;

//環境設定
vec3 worldPosition;
vec3 worldNormal;
vec3 lightVector;
vec3 viewVector;

// variables used during runtime
vec3 noiseNormal;
float brightness;


// special numbers used in the hash for generating random vectors
#define MOD3 vec3(.1031,.11369,.13787)

// hash for generating random vectors
vec3 hash33(vec3 p3)
{
	p3 = fract(p3 * MOD3);
    p3 += dot(p3, p3.yxz+19.19);
    return -1.0 + 2.0 * fract(vec3((p3.x + p3.y)*p3.z, (p3.x+p3.z)*p3.y, (p3.y+p3.z)*p3.x));
}

// function to generate perlin noise based on vertex pos
float perlin_noise(vec3 p)
{
    vec3 pi = floor(p);
    vec3 pf = p - pi;
    
    vec3 w = pf * pf * (3.0 - 2.0 * pf);
    
    return 	mix(
        		mix(
                	mix(dot(pf - vec3(0, 0, 0), hash33(pi + vec3(0, 0, 0))), 
                        dot(pf - vec3(1, 0, 0), hash33(pi + vec3(1, 0, 0))),
                       	w.x),
                	mix(dot(pf - vec3(0, 0, 1), hash33(pi + vec3(0, 0, 1))), 
                        dot(pf - vec3(1, 0, 1), hash33(pi + vec3(1, 0, 1))),
                       	w.x),
                	w.z),
        		mix(
                    mix(dot(pf - vec3(0, 1, 0), hash33(pi + vec3(0, 1, 0))), 
                        dot(pf - vec3(1, 1, 0), hash33(pi + vec3(1, 1, 0))),
                       	w.x),
                   	mix(dot(pf - vec3(0, 1, 1), hash33(pi + vec3(0, 1, 1))), 
                        dot(pf - vec3(1, 1, 1), hash33(pi + vec3(1, 1, 1))),
                       	w.x),
                	w.z),
    			w.y);
}

// generates a detailed noise map
float noiseMap(vec3 pos, vec3 offset)
{
    float noiseVal = (perlin_noise((pos + offset)*5.5) + (perlin_noise((pos + offset)*100.5)/6.0) + (perlin_noise((pos + offset)*500.5)/12.0))*1.3;
    return noiseVal;
}

// converts a noise / height value to color
vec3 noiseToColor(float noiseVal)
{
    // color regions
    vec3 color;
    if(noiseVal < 0.0) color = waterColor;
    else if(noiseVal >= 0.0 && noiseVal < 0.05) color = landBorderColor;
    else if(noiseVal >= 0.05 && noiseVal < 0.3) color = lowLandColor;
    else if(noiseVal >= 0.3 && noiseVal < 0.6) color = midLandColor;
    else if(noiseVal > 0.6) color = highLandColor;
    return color;
}

// calculates the normal map for the generated noiseMap, takes in vNormal
vec3 calculateNoiseNormal(vec3 pos, vec3 normal)
{
    // get the derivates: A derivate is the difference between a value of the current pixel and the current pixel + 1
    vec3 dpx = dFdx( pos );
    vec3 dpy = dFdy( pos );
    
    // get the height values of the neighbour pixels
    float height =  noiseMap(pos, offset);
    float heightDx = noiseMap(pos + dpx, offset);
    float heightDy = noiseMap(pos + dpy, offset);
    
    // create a vector to offset the heightMap
    vec3 normalOffset = 4.0*(((heightDy-height) * -normalize(dpy)) + ((heightDx - height) * -normalize(dpx)));
    if(height < 0.0 && heightDx < 0.0 && heightDy < 0.0 ) normalOffset/=8.5;// = vec3(0.0,0.0,0.0);
    
    // covert normal to world space before returning
    //return normal + normalOffset;
    return normalize( vec3( modelmatrix * vec4(  normal + normalOffset, 0.0 ) ) );
}


// calculate several useful parameters like worldPos, worldNormal etc
void SetSceneStatus()
{
    worldPosition = ( modelmatrix * vec4( vPosition, 1.0 )).xyz;
    worldNormal = normalize( vec3( modelmatrix * vec4( vNormal, 0.0 ) ) );
    lightVector = lightintensity*normalize( lightPosition - worldPosition );
    viewVector = normalize(cameraPosition - worldNormal);
}


vec4 BaseLight()
{
    noiseNormal = calculateNoiseNormal(vPosition, vNormal);
    brightness = clamp((dot( noiseNormal * 2.0, lightVector)+0.3)/4.0,0.02,1.2);
    
    vec4 result = vec4( vec3(brightness), 1.0 );
    return vec4(result);
}

vec4 SpecularMapping()
{
    vec4 baselight = BaseLight();
    float atten = 3.0;
    float shine = 8.5;
    float lightIntensity = 0.0;
    float bright = 9.0;
    
    float noiseVal = noiseMap(vPosition, offset);
    vec4 newCol = vec4(noiseToColor(noiseVal), 1.0);
    vec4 diffusereflection = clamp((baselight*newCol*0.4)*bright ,0.05,1.0);
    vec4 speculareflection = clamp(baselight * atten * vec4(vec3(pow(clamp(dot(reflect(lightVector, noiseNormal), viewVector),0.0,1.0), shine)),1.0), 0.0,1.0);
    
	return diffusereflection+speculareflection;
}


void main()
{
    //...............................fresnel...........................................................
    vec3 kenormal = normalize(fNormal);
    vec3 eye = normalize(-fPosition.xyz);
    float rim = smoothstep(start, end, 1.0 - dot(kenormal, eye));
    //gl_FragColor = vec4( clamp(rim, 0.0, 1.0) * kealpha * kecolor, 1.0 ); 
    //..................................................................................................
    
  
    SetSceneStatus();
    vec4 baseColor = SpecularMapping();
    gl_FragColor =  baseColor + vec4(clamp((dot(worldNormal, lightVector)+0.3)*2.0,0.0,1.0)*(clamp(rim, 0.0, 1.0) * kealpha * kecolor), 1.0 );
                    
    // gl_FragColor = vec4(vec3(normalSpecular),1.0);
    // gl_FragColor = vec4(noiseNormal,1.0);
}`;

export const toonVert = `
precision highp float;
precision highp int;


// Examples of variables passed from vertex to fragment shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying vec2 vUv2;
varying mat4 modelmatrix;

// Default attributes provided by THREE.js. Attributes are only available in the
// vertex shader. You can pass them to the fragment shader using varyings
// attribute vec3 position;
// attribute vec3 normal;
// attribute vec2 uv;
// attribute vec2 uv2;


// uniform mat3 normalMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 modelMatrix;

// varying vec3 fNormal;
// varying vec3 fPosition;

void main()
{
    // To pass variables to the fragment shader, you assign them here in the
    // main function. Traditionally you name the varying with vAttributeName
    vNormal = normal;
    vUv = uv;
    // vUv2 = uv2;
    vPosition = position;
    


    //fNormal = normalMatrix  *  normal; 
    // fNormal = normalMatrix  *  vNormal;
    vec4 pos = modelViewMatrix * vec4(position, 1.0);
    // fPosition = pos.xyz;
    gl_Position = projectionMatrix * pos;

    modelmatrix = modelMatrix;
}
`;

export const toonFrag = `
// Set the precision for data types used in this shader
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader
// uniform mat4 modelMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 viewMatrix;
// uniform mat3 normalMatrix;

// Default uniforms provided by ShaderFrog.
// uniform vec3 cameraPosition;
// uniform float time;

// A uniform unique to this shader. You can modify it to the using the form
// below the shader preview. Any uniform you add is automatically given a form
// uniform sampler2D uColorMap;
// uniform sampler2D uNormalMap;
uniform vec3 color;
uniform vec3 lightPosition;
uniform float brightColor;
uniform float darkColor;
uniform float midColor;


// Example varyings passed from the vertex shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
// varying vec2 vUv2;
varying mat4 modelmatrix;


// view variables
vec4 viewNormal;
vec3 viewDirs;



void main() {

    // Calculate the real position of this pixel in 3d space, taking into account
    // the rotation and scale of the model. It's a useful formula for some effects.
    // This could also be done in the vertex shader
    vec3 worldPosition = ( modelmatrix * vec4( vPosition, 1.0 )).xyz;

    // Calculate the normal including the model rotation and scale
    vec3 worldNormal = normalize( vec3( modelmatrix * vec4( vNormal, 0.0 ) ) );

    vec3 lightVector = normalize( lightPosition - worldPosition );

    // An example simple lighting effect, taking the dot product of the normal
    // (which way this pixel is pointing) and a user generated light position
    float brightness = clamp(dot( worldNormal, lightVector ),0.1,1.0);
    if(brightness > 0.0 &&  brightness < 0.2)
    {
        brightness = darkColor;
    }
    else if (brightness >= 0.2 &&  brightness < 0.5)
    {
        brightness = midColor;
    }
    else if (brightness >= 0.5 &&  brightness < 1.01)
    {
        brightness = brightColor;
    }
    
    
    viewNormal = viewMatrix * vec4(vNormal, 1.0);
    viewDirs = normalize(vec3(cameraPosition.xyz-viewNormal.xyz));
    float specular = pow(dot(reflect(-lightVector, worldNormal), viewDirs), 5.0) * clamp(dot(worldNormal, lightVector), 0.0, 1.0);
    
    
    
    
    // Fragment shaders set the gl_FragColor, which is a vector4 of
    // ( red, green, blue, alpha ).
    // gl_FragColor = vec4( texture2D(uColorMap, vUv).rgb * brightness, 1.0 ) + vec4(specular);
    gl_FragColor = vec4( color * brightness, 1.0 ) + vec4(specular);

}
`;


export const fireVert = `

// Set the precision for data types used in this shader
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader
// uniform mat4 modelMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 viewMatrix;
// uniform mat3 normalMatrix;

// Default attributes provided by THREE.js. Attributes are only available in the
// vertex shader. You can pass them to the fragment shader using varyings
// attribute vec3 position;
// attribute vec3 normal;
// attribute vec2 uv;
// attribute vec2 uv2;

// Examples of variables passed from vertex to fragment shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying mat4 modelmatrix;
// varying vec2 vUv2;

void main() {

    // To pass variables to the fragment shader, you assign them here in the
    // main function. Traditionally you name the varying with vAttributeName

    vNormal = normal;
    vUv = uv;
    modelmatrix = modelMatrix;
    vPosition = position;

    // This sets the position of the vertex in 3d space. The correct math is
    // provided below to take into account camera and object data.
    gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
}

`;


export const fireFrag = `

// Set the precision for data types used in this shader
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader
// uniform mat4 modelMatrix;
// uniform mat4 modelViewMatrix;
// uniform mat4 projectionMatrix;
// uniform mat4 viewMatrix;
// uniform mat3 normalMatrix;

// Default uniforms provided by ShaderFrog.
// uniform vec3 cameraPosition;
// uniform float time;

// A uniform unique to this shader. You can modify it to the using the form
// below the shader preview. Any uniform you add is automatically given a form
uniform vec3 lightPosition;
uniform float step1;
uniform float step2;
uniform vec3 outerColor;
uniform vec3 middleColor;
uniform vec3 innerColor;

// Example varyings passed from the vertex shader
varying vec3 vPosition;
varying vec3 vNormal;
varying vec2 vUv;
varying mat4 modelmatrix;
// varying vec2 vUv2;

vec4 viewNormal;
vec3 viewDirs;

vec3 lerp(vec3 A, vec3 B, float val)
{
    return ((B-A)*val) + A;   
}

void main() {

    // Calculate the real position of this pixel in 3d space, taking into account
    // the rotation and scale of the model. It's a useful formula for some effects.
    // This could also be done in the vertex shader
    vec3 worldPosition = ( modelmatrix * vec4( vPosition, 1.0 )).xyz;

    // Calculate the normal including the model rotation and scale
    vec3 worldNormal = normalize( vec3( modelmatrix * vec4( vNormal, 0.0 ) ) );
    
    vec3 Vdir = normalize(cameraPosition.xyz - worldPosition);
    float gradient = dot(Vdir, worldNormal);
    
    vec3 color;
    // float step = 0.7;
    if(gradient <= step1) 
    {
        color = lerp(outerColor, middleColor, (gradient - 0.0)/(step1 - 0.0));
    }
    else if (gradient > step1 && gradient < step2)
    {
        color = lerp(middleColor, innerColor, (gradient - step1)/(step2 - step1));
    }
    else if (gradient > step2)
    {
        color = innerColor;
    }
    
    gl_FragColor = vec4( color, 1.0 );
}
`;



export const LoadingProfileVert = `
// Set the precision for data types used in this shader
precision highp float;
precision highp int; 

varying vec3 vPosition;
varying mat4 modelmatrix;

varying vec2 vUv;
void main()
{
    modelmatrix = modelMatrix;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`;

export const LoadingProfileFrag = `
// Set the precision for data types used in this shader
precision highp float;
precision highp int;

// Default THREE.js uniforms available to both fragment and vertex shader
//uniform mat4 modelMatrix;
//uniform mat4 modelViewMatrix;
//uniform mat4 projectionMatrix;
//uniform mat4 viewMatrix;
//uniform mat3 normalMatrix;

// Default uniforms provided by ShaderFrog.
//uniform vec3 cameraPosition;
uniform float time;

// A uniform unique to this shader. You can modify it to the using the form
// below the shader preview. Any uniform you add is automatically given a form
uniform vec3 color;
uniform vec3 filledColor;
uniform vec3 backgroundColor;
uniform vec3 proCutoutColor;
uniform vec3 circlePosition;
uniform vec3 circlePosition2;
uniform vec3 cutoutCentre;
uniform float percent;
uniform float cutoutRadius;


// Example varyings passed from the vertex shader
varying vec3 vPosition;
varying mat4 modelmatrix;

float lerp(float a, float b, float w) 
{
  return a + w*(b-a);
}

void main() {

    // Calculate the real position of this pixel in 3d space, taking into account
    // the rotation and scale of the model. It's a useful formula for some effects.
    // This could also be done in the vertex shader
    vec3 worldPosition = ( modelmatrix * vec4( vPosition, 1.0 )).xyz;

    // distance of point to the base circle centre;
    float dist = length(worldPosition - circlePosition);
    // displacement from the base circle centre to point
    vec3 disp = normalize(worldPosition - circlePosition);
    // distance of point to the top circle centre;
    float dist2 = length(worldPosition - circlePosition2 - vec3(0.0,(cos(time*2.0)) * 0.07,0.0));
    // displacement from the top circle centre to point
    vec3 disp2 = normalize(worldPosition - circlePosition2);
    // frequency of the base and top circle
    float mult = sin(time) * 15.0;
    // amplitude coefficient of the base and top circle
    float amp = 0.02;
    // wave eqn of base circle
    float wave  = (cos(atan(disp.y/disp.x) * mult) * amp);
    // wave eqn of base circle
    float wave2  = (cos(atan(disp2.y/disp2.x) * mult) * amp);
    // used to reduce the waviness of the top circle to the downward stroke
    float motionRectifier = min(0.0, cos(time*2.0));
    // makes waves show only on bottom half of top circle
    float halfCirc = min(0.0, disp2.y) * motionRectifier;
    wave2 *= halfCirc;
    //float yperc = (cos(time *2.0) + 1.0)/2.0;
    float worldPercent = lerp(-1.0, 0.6, percent); /*(percent * 2.0) - 1.0*/
    float filledWave = (cos((time + worldPosition.x) * 10.0) * 0.02);
    if(dist < 0.6 + wave && dist > 0.57 + wave)
        gl_FragColor = vec4( color, 1.0 );
    else if (dist < 0.55 + wave && worldPosition.y < worldPercent + filledWave)
        gl_FragColor = vec4( filledColor, 1.0 );
    else if (dist2 < 0.3 + wave2 && dist2 > 0.27 + wave2)
        gl_FragColor = vec4( color, 1.0 );
    else if (dist2 < 0.24 + wave2 && worldPosition.y < worldPercent + filledWave)
        gl_FragColor = vec4( filledColor, 1.0 );
    else
        gl_FragColor = vec4( backgroundColor, 1.0 );

    if (length(worldPosition - cutoutCentre) > cutoutRadius)
        gl_FragColor = vec4( proCutoutColor, 1.0 );
}
`;
// random comment inserted at the end to test pushing from new pc

