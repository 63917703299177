import { React, useEffect, useRef } from 'react';
import {
  ContactContainer,
  ContactCanvas,
  ContactWrap,
  ContactBox,
  ContactBoxTop,
  ContactPhoto,
  ContactBoxBottom,
  ContactTitle
} from './contact.style';

import profile from '../../assets/model/profile/profilepic.glb';

import {
  vert0,
  frag0,
  LoadingProfileVert,
  LoadingProfileFrag
} from '../../assets/shader/shader1';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import me2 from '../../assets/shader/Shaderprofilepic/me2.png';
import closest from '../../assets/shader/Shaderprofilepic/closest.png';
import middle from '../../assets/shader/Shaderprofilepic/middle.png';
import farthest from '../../assets/shader/Shaderprofilepic/farthest.png';

import profilePhoto from '../../assets/photo/profile.webp';
import { ButtonNormal } from '../Button';
import { lerp } from 'three/src/math/MathUtils';

const ProfileCanvas = () => {
  // * list referenced elements
  const mountRef = useRef(null);

  useEffect(() => {
    let profileCanvas = mountRef.current;

    let rendererXSize, rendererYSize;

    rendererXSize = mountRef.current.offsetWidth;

    if (window.innerWidth <= 999) {
      rendererYSize = rendererXSize;
    } else {
      rendererYSize = window.innerHeight / 2;
    }

    let profileObj = null;
    let scene = new THREE.Scene();
    let camera = new THREE.PerspectiveCamera(
      23,
      rendererXSize / rendererYSize,
      0.1,
      1000
    );
    camera.position.z = 2.3;
    let renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setClearColor('#ffffff');
    renderer.setSize(rendererXSize, rendererYSize);

    mountRef.current.appendChild(renderer.domElement);

    let clock = new THREE.Clock();

    //........render only on event.................
    var useAnimateLoop = true;
    // var lerpTime = 0.0;
    let time = 0;
    const totalLerpTime = 3.0;
    //............................................

    // mouse position tracking function
    let mouse = new THREE.Vector2(0.0001, 0.0001);
    let onMouseMove = function (event) {
      event.preventDefault();

      let rect = mountRef.current.getBoundingClientRect();

      let xAxis = (event.clientX - rect.x) / rendererXSize - 0.5;
      let yAxis = (event.clientY - rect.y) / rendererYSize - 0.5;
      mouse.x = lerp(mouse.x, xAxis, mouseLerp);
      mouse.y = lerp(mouse.y, yAxis, mouseLerp);

      if (loadedTextures) time = 0;
      if (!useAnimateLoop) {
        useAnimateLoop = true;
        render();
      }
    };

    mountRef.current.addEventListener('mousemove', onMouseMove);

    let onWindowResize = function () {
      let rendererXSize =
          window.innerWidth -
          (window.innerWidth - mountRef.current.offsetWidth),
        rendererYSize = window.innerHeight / 2;

      renderer.setSize(rendererXSize, rendererYSize);
      camera.aspect = rendererXSize / rendererYSize;
      camera.updateProjectionMatrix();
    };

    window.addEventListener('resize', onWindowResize, false);

    var Map1; // = new THREE.TextureLoader().load(me2);
    var Map2; // = new THREE.TextureLoader().load(closest);
    var Map3; // = new THREE.TextureLoader().load(middle);
    var Map4; // = new THREE.TextureLoader().load(farthest);
    var materialForTheProfile;
    var loader;
    // var time = 0;
    var loadingMat;
    let loadedTextures = false;
    let loadState = 0;
    var lerpval = 0.05;
    const mouseLerp = 0.5;

    const render = () => {
      if (useAnimateLoop) requestAnimationFrame(render);
      if (time >= totalLerpTime && loadedTextures) useAnimateLoop = false;

      time += clock.getDelta();

      if (!loadedTextures) {
        loadingMat.uniforms.time.value = time;
        loadingMat.uniforms.percent.value = lerp(
          loadingMat.uniforms.percent.value,
          loadState / 6,
          lerpval
        );
      }
      // console.log('time: ' + time2);
      renderer.render(scene, camera);
    };

    function LoadAsync(num) {
      switch (num) {
        case 0:
          Map1 = new THREE.TextureLoader().load(
            me2,
            function (texture) {
              loadState = 1;
              LoadAsync(num + 1);
            }, // on load callback
            undefined,
            function (err) {
              console.error(err);
            }
          );
          break;
        case 1:
          Map2 = new THREE.TextureLoader().load(
            closest,
            function (texture) {
              loadState = 2;
              LoadAsync(num + 1);
            } // on load callback
          );
          break;
        case 2:
          Map3 = new THREE.TextureLoader().load(
            middle,
            function (texture) {
              loadState = 3;
              LoadAsync(num + 1);
            } // on load callback
          );
          break;
        case 3:
          Map4 = new THREE.TextureLoader().load(
            farthest,
            function (texture) {
              loadState = 4;
              LoadAsync(num + 1);
            } // on load callback
          );
          break;
        case 4:
          // console.log('all profile textures loaded');
          materialForTheProfile = new THREE.ShaderMaterial({
            uniforms: {
              mousepos: { value: mouse },
              map1: { value: Map1 },
              map2: { value: Map2 },
              map3: { value: Map3 },
              map4: { value: Map4 },
              circleCentre: { value: new THREE.Vector3(0, 0, -12) },
              cutoutRadius: { value: 1.9 }
            },
            vertexShader: vert0,
            fragmentShader: frag0
          });
          loadState = 5;
          LoadAsync(num + 1);
          break;
        case 5:
          profileObj.position.set(0, 0, -12);
          // profileObj.rotation.set(1.571, 3.14159, 0);
          profileObj.material = materialForTheProfile;
          loadState = 6;
          loadedTextures = true;
          break;
        default:
          break;
      }
    }

    //----- load laoding screen ----------------------------------------------------

    let zPos = -6; // controls how zoomed it appears
    loadingMat = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: time },
        color: { value: new THREE.Vector3(1.0, 1.0, 1.0) },
        filledColor: { value: new THREE.Vector3(0.964706, 0.784314, 0.266667) },
        backgroundColor: { value: new THREE.Vector3(0.1333, 0.1333, 0.1333) },
        proCutoutColor: { value: new THREE.Vector3(1.0, 1.0, 1.0) },
        circlePosition: { value: new THREE.Vector3(0, -1, zPos) },
        circlePosition2: { value: new THREE.Vector3(0, 0.15, zPos) },
        cutoutCentre: { value: new THREE.Vector3(0, 0, zPos) },
        percent: { value: 0.0 },
        cutoutRadius: { value: 1.05 }
      },
      vertexShader: LoadingProfileVert,
      fragmentShader: LoadingProfileFrag
    });

    // loadingMat.extensions.derivatives = true;

    loader = new GLTFLoader();
    loader.load(
      profile,
      gltf => {
        profileObj = gltf.scene.children[0];
        profileObj.position.set(0, 0, zPos);

        profileObj.rotation.set(1.571, 3.14159, 0);

        profileObj.material = loadingMat;

        scene.add(gltf.scene);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    render();
    //-----------------------------------------------------------------------------

    LoadAsync(0);

    return () => {
      // remove the renderer from the canvas
      profileCanvas.removeChild(renderer.domElement);
    };
  }, []);

  return <ContactCanvas ref={mountRef}></ContactCanvas>;
};

const Contact = () => {
  let mobile = false;
  const getAspectRatio = () => {
    let width = window.innerWidth;
    let height = window.innerHeight;

    if (width > height) {
      return 'landscape';
    }
    return 'portrait';
  };

  if (getAspectRatio() === 'portrait') {
    mobile = true;
  } else {
    mobile = false;
  }

  return (
    <ContactContainer id="contact">
      <ContactWrap>
        <ContactBox>
          <ContactBoxTop>
            {mobile ? (
              <ContactPhoto src={profilePhoto} alt="" />
            ) : (
              <ProfileCanvas />
            )}
          </ContactBoxTop>
          <ContactBoxBottom>
            <ContactTitle>Let's work together</ContactTitle>
            <ButtonNormal href="mailto:razzminkelvin@gmail.com">
              contact me
            </ButtonNormal>
          </ContactBoxBottom>
        </ContactBox>
      </ContactWrap>
    </ContactContainer>
  );
};

export default Contact;
