import { useState } from 'react';

import { ThemeProvider } from 'styled-components';
import GlobalStyle from './GlobalStyle';
import theme from './theme';

import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Hero from './components/Hero';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  const [sideBar, setSideBar] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Header sideBar={sideBar} setSideBar={setSideBar} />
      <Sidebar sideBar={sideBar} setSideBar={setSideBar} />
      <Hero />
      <Skills />
      <Contact />
      <Footer />
    </ThemeProvider>
  );
}

export default App;
