import { useEffect } from 'react';
import {
  SidebarContainer,
  SidebarWrap,
  SidebarMenu,
  SidebarItem,
  SidebarLink,
} from './sidebar.style';

const Sidebar = ({ sideBar, setSideBar }) => {
  const links = [
    { name: 'home', path: 'home' },
    { name: 'skills', path: 'skills' },
    { name: 'contact me', path: 'contact' },
  ];

  useEffect(() => {
    if (sideBar) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'unset';
    }
  }, [sideBar]);

  return (
    <SidebarContainer show={sideBar}>
      <SidebarWrap>
        <SidebarMenu>
          {links.map((link, i) => {
            return (
              <SidebarItem key={i}>
                <SidebarLink
                  onClick={() => setSideBar(!sideBar)}
                  to={link.path}
                  smooth={true}
                  duration={500}
                  spy={true}
                  exact="true"
                  offset={-80}
                >
                  {link.name}
                </SidebarLink>
              </SidebarItem>
            );
          })}
        </SidebarMenu>
      </SidebarWrap>
    </SidebarContainer>
  );
};

export default Sidebar;
