import styled from 'styled-components';
import { Link } from 'react-scroll';
import '@fontsource/chakra-petch';

const primary = props => props.theme.primary;
const bg = props => props.theme.bg;
const typeface = props => props.theme.typeface;

export const NavbarContainer = styled.header`
  background-color: ${bg};
  color: ${typeface};
  height: 80px;
  display: flex;
  justify-content: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
`;

export const NavbarWrap = styled.nav`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 24px;
  max-width: 1200px;
`;

export const NavbarLogo = styled.p`
  font-family: 'Chakra Petch', sans-serif;

  color: ${typeface};
  display: flex;
  justify-content: flex-content;
  align-items: center;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
`;

export const MobileMenu = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  position: relative;

  width: 60px;
  height: 100%;
  padding: 8px 20px;

  font-size: 1.5rem;

  cursor: pointer;

  @media screen and (max-width: 768px) {
    display: flex;
  }
`;

export const Hamburger = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -12px;

  transform: ${({ sideBar }) => (sideBar ? 'scaleY(0)' : 'scaleY(1)')};
  transform-origin: center;
  opacity: ${({ sideBar }) => (sideBar ? '0' : '1')};

  transition: 300ms ease;
`;

export const Close = styled.div`
  position: absolute;
  top: 50%;
  margin-top: -12px;

  transform: ${({ sideBar }) => (sideBar ? 'scaleY(1)' : 'scaleY(0)')};
  transform-origin: center;
  opacity: ${({ sideBar }) => (sideBar ? '1' : '0')};

  transition: 300ms ease;
`;

export const NavMenu = styled.ul`
  display: flex;

  list-style: none;
  text-align: center;

  @media screen and (max-width: 768px) {
    display: none;
  } ;
`;

export const NavItem = styled.li`
  display: flex;
  align-items: center;
`;

export const NavLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;

  padding: 0 20px;

  color: inherit;
  text-transform: capitalize;
  cursor: pointer;
  border-bottom: 3px solid transparent;

  &.active {
    border-bottom: 3px solid ${primary};
  }
`;
