import styled from 'styled-components';
import { Link } from 'react-scroll';

const bg = props => props.theme.bg;
const typeface = props => props.theme.typeface;

export const SidebarContainer = styled.div`
  background: ${bg};
  color: ${typeface};

  display: none;
  width: 100%;

  position: fixed;
  top: 80px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  transition: all 300ms ease;

  @media screen and (max-width: 768px) {
    display: flex;
    transform: ${({ show }) => (show ? 'translateX(0)' : 'translateX(100%)')};
  }
`;

export const SidebarWrap = styled.div`
  width: 100%;
  padding: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SidebarMenu = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 100%;
  height: 100%;
`;

export const SidebarItem = styled.li`
  margin: 12px 0;
  width: 100%;

  list-style: none;

  display: flex;
  justify-content: center;
  align-items: center;

  background: ${props => props.theme.primary};
`;

export const SidebarLink = styled(Link)`
  color: inherit;

  padding: 16px 0;
  text-align: center;

  width: 100%;

  cursor: pointer;
  text-transform: capitalize;
`;
