import React from 'react';
import {
  FaGithub,
  FaLinkedin,
  FaTelegramPlane,
  FaEnvelope
} from 'react-icons/fa';

import {
  FooterContainer,
  FooterWrap,
  FooterSocialMenu,
  FooterSocialItem,
  FooterSocialLink
} from './footer.style';

const Footer = () => {
  const socials = [
    {
      icon: <FaLinkedin />,
      url: 'https://www.linkedin.com/in/oye-seun',
      ariaLabel: 'link to linkedin profile'
    },
    {
      icon: <FaGithub />,
      url: 'https://www.github.com/oye-seun',
      ariaLabel: 'link to github profile'
    },
    {
      icon: <FaTelegramPlane />,
      url: 'https://t.me/kingfishern',
      ariaLabel: 'link to telegram chat'
    },
    {
      icon: <FaEnvelope />,
      url: 'mailto:razzminkelvin@gmail.com',
      ariaLabel: 'link to email'
    }
  ];

  return (
    <FooterContainer>
      <FooterWrap>
        <FooterSocialMenu>
          {socials.map(({ url, icon, ariaLabel }, i) => {
            return (
              <FooterSocialItem key={i}>
                <FooterSocialLink
                  href={url}
                  target="_blank"
                  aria-label={ariaLabel}
                >
                  {icon}
                </FooterSocialLink>
              </FooterSocialItem>
            );
          })}
        </FooterSocialMenu>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
