import { createGlobalStyle } from 'styled-components';
import '@fontsource/fredoka';
import '@fontsource/poiret-one';
import '@fontsource/chakra-petch';

const primary = props => props.theme.primary;

const GlobalStyle = createGlobalStyle`
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    * {
        scrollbar-width: auto;
        scrollbar-color: ${primary} #ffffff;
    }

    /* Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
        width: 16px;
    }

    *::-webkit-scrollbar-track {
        background: #ffffff;
    }

    *::-webkit-scrollbar-thumb {
        background-color: ${primary};
        border-radius: 10px;
        border: 3px solid #ffffff;
    }
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    body {
        font-family: 'Fredoka', sans-serif;
    }

    h1, h3 {
        font-family: "Chakra Petch", sans-serif;
    }
    
    h2 {
        font-family: 'Poiret One', cursive;
    }
    
`;

export default GlobalStyle;
