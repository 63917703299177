import React, { useEffect, useRef } from 'react';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { cubevert, cubefrag } from '../../../../assets/shader/shader1';

import rubix from '../../../../assets/model/rubix/rubix.glb';

import {
  SoftSkillsWrap,
  Skillrow,
  SkillP,
  SkillCanvas,
  SkillrowSection,
  SkillTitle
} from '../soft-skills.style';
import { RiTeamFill } from 'react-icons/ri';

//............ global...................................
let renderer = null;

//...........observer stuff....................................................................
let initialPixelRatio = null;
let intersectionRatio = 0;
// let intersecting = null;
const observer = new IntersectionObserver(
  ([entry]) => {
    intersectionRatio = entry.intersectionRatio;
    renderer.setPixelRatio(initialPixelRatio * intersectionRatio);
    // console.log('isInViewport1: ', entry.intersectionRatio);
  },
  {
    // threshold: [0.0, 0.6, 1.0]
    threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
  }
);
//............................................................................................

const Cubes = () => {
  // list referenced elements
  const mountRef = useRef(null);
  const canvasParent = useRef(null);

  useEffect(() => {
    let clock = new THREE.Clock();
    let mountRefWhole = mountRef.current;

    let rendererXSize, rendererYSize;

    //........................................................................................................
    observer.observe(mountRef.current);
    //........................................................................................................

    // function to return orientation of device
    const aspectRatio = () => {
      let width = window.innerWidth;
      let height = window.innerHeight;

      if (width > height) {
        return 'landscape';
      }
      return 'portrait';
    };


    // update renderer width and height
    const resizeScreen = () => {
      if (window.innerWidth <= 768) {
        rendererXSize =
          window.innerWidth - (window.innerWidth - mountRefWhole.clientWidth);

        if (window.innerWidth >= 480) {
          rendererYSize = window.innerHeight * 0.5;
        } else {
          rendererYSize = window.innerHeight * 0.5;
          rendererXSize =
            window.innerWidth -
            (window.innerWidth - mountRefWhole.clientWidth) * 0.5;
        }
      } else {
        rendererXSize = canvasParent.current.clientWidth / 2;
        if (aspectRatio() === 'landscape') {
          rendererYSize = window.innerHeight * 0.5;
        } else if (aspectRatio() === 'portrait') {
          rendererYSize = rendererXSize;
        }
      }
    };

    resizeScreen();

    // ? MODEL START (scene, camera, renderer) ---------------------------------------
    let mixer = null;
    let rubixObj = [];

    let scene = new THREE.Scene();
    let camera = new THREE.PerspectiveCamera(
      23,
      rendererXSize / rendererYSize,
      0.1,
      1000
    );
    camera.position.z = 2.3;
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    initialPixelRatio = window.devicePixelRatio;
    renderer.setClearColor('#ffc600');
    renderer.setSize(rendererXSize, rendererYSize);

    // attach the renderer to the canvas
    mountRefWhole.appendChild(renderer.domElement);

    // * add custom 3d object (cubes) --------------------
    const bcolor = new THREE.Vector3(0.5, 0.5, 0.5);
    const fresnelcolor1 = new THREE.Vector3(1.0, 1.0, 1.0);

    var material = new THREE.ShaderMaterial({
      uniforms: {
        basecolor: { value: bcolor },
        kecolor: { value: fresnelcolor1 },
        start: { value: 0.4 },
        end: { value: 1.0 },
        kealpha: { value: 1.0 }
      },
      vertexShader: cubevert,
      fragmentShader: cubefrag
    });

    const gcolor = new THREE.Vector3(1, 0.9255, 0.71765);
    var glowmaterial = new THREE.ShaderMaterial({
      uniforms: {
        basecolor: { value: gcolor },
        kecolor: { value: fresnelcolor1 },
        start: { value: 0.4 },
        end: { value: 1.0 },
        kealpha: { value: 1.0 }
      },
      vertexShader: cubevert,
      fragmentShader: cubefrag
    });

    const loader = new GLTFLoader();
    loader.load(
      rubix,
      gltf => {
        for (let i = 0; i < 26; i++) {
          rubixObj[i] = gltf.scene.children[i];
          if (i !== 12) {
            rubixObj[i].material = material;
          } else {
            rubixObj[i].material = glowmaterial;
          }
        }

        mixer = new THREE.AnimationMixer(gltf.scene);
        var actions = [];

        for (let i = 0; i < 26; i++) {
          actions[i] = mixer.clipAction(gltf.animations[i]);
          actions[i].play();
        }

        scene.add(gltf.scene);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    scene.position.set(0, -0.3, -1.1);
    scene.rotation.set(0.45, 0.7, 0.0);

    let dirLight = new THREE.DirectionalLight(0xffffff, 6);
    dirLight.position.set(6, 7, 5);
    dirLight.castShadow = true;
    scene.add(dirLight);

    let light = new THREE.PointLight(0xffffff, 1, 500);
    light.position.set(5, 0, 55);
    scene.add(light);

    let ambientLight = new THREE.AmbientLight(0x404040, 3);
    scene.add(ambientLight);

    // * WINDOW RESIZE EVENT LISTENER----------------------------------------------------
    // const onWindowResize = () => {
    //   resizeScreen();
    //   camera.current.aspect = rendererXSize / rendererYSize;
    //   camera.current.updateProjectionMatrix();
    //   renderer.current.setSize(rendererXSize, rendererYSize);
    //   runRender();
    // };

    camera.aspect = rendererXSize / rendererYSize;
    camera.updateProjectionMatrix();

    // add event listener to window
    // window.addEventListener('resize', onWindowResize, false);

    // function to animate 3D asset in real time
    const render = () => {
      requestAnimationFrame(render);
      let dt = clock.getDelta();

      if (mixer != null) {
        mixer.update(dt);
      }
      renderer.render(scene, camera);
    };

    // call render function
    render();

    return () => {
      // remove the renderer from the canvas
      mountRefWhole.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <SoftSkillsWrap rowBgColor="#ffc600" rowColor="#222">
      <Skillrow ref={canvasParent}>
        <SkillrowSection margRight>
          <SkillTitle>
            Teamworking &nbsp;
            <RiTeamFill />
          </SkillTitle>
          <SkillP>
            As a team player, I am focused on the goal and cohesiveness of the
            entire team, with good human relations and networking skills.
          </SkillP>
        </SkillrowSection>
        <SkillrowSection borderRadius>
          <SkillCanvas ref={mountRef}></SkillCanvas>
        </SkillrowSection>
      </Skillrow>
    </SoftSkillsWrap>
  );
};

export default Cubes;
