import React from 'react';
import {
  HeroP,
  HeroContainer,
  HeroWrap,
  HeroContent,
  HeroH1,
  HeroBg,
  VideoBg,
  TextWrap,
} from './hero.style';

import video from '../../assets/video/game_dev.mp4';

import { ButtonNormal } from '../Button';

const Hero = () => {
  return (
    <HeroContainer id="home">
      <HeroWrap>
        <HeroBg>
          <VideoBg autoPlay loop muted src={video} type="video/mp4" />
        </HeroBg>
        <HeroContent>
          <TextWrap>
            <HeroH1>Hello, i'm Seun</HeroH1>
            <HeroP>
              I have worked on numerous projects, touching various aspects of
              game design and execution.
            </HeroP>
          </TextWrap>
          <ButtonNormal href="mailto:razzminkelvin@gmail.com">
            contact me
          </ButtonNormal>
        </HeroContent>
      </HeroWrap>
    </HeroContainer>
  );
};

export default Hero;
