import styled from 'styled-components';
const typeface = props => props.theme.typeface;

export const SoftSkillsContainer = styled.div`
  color: ${typeface};
  width: 100%;
`;

export const SoftSkillsWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${({ rowBgColor }) =>
    rowBgColor ? rowBgColor : 'inherit'};
  color: ${({ rowColor }) => (rowColor ? rowColor : 'inherit')};
`;

export const SkillH1 = styled.h2`
  text-transform: capitalize;
  margin: 24px 32px;
`;

export const Skillrow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flip }) => (flip ? 'row-reverse' : 'row')};

  max-width: 1200px;
  margin: 0 auto;

  padding: 64px 32px;

  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const SkillrowSection = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: ${({ margRight }) => (margRight ? '20px' : '0')};
  margin-left: ${({ margLeft }) => (margLeft ? '20px' : '0')};

  border-radius: ${({ borderRadius }) => (borderRadius ? '30px' : '0')};

  overflow: hidden;

  @media screen and (max-width: 768px) {
    margin: 0;
    align-items: flex-start;
  }
`;

export const SkillTitle = styled.h2`
  display: flex;
  align-items: center;
  margin: 20px 0;
`;

export const SkillP = styled.p``;

export const SkillCanvas = styled.div`
  height: 100%;
  width: 100%;
`;
