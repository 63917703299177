import React, { useEffect, useRef } from 'react';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import {
  vert2,
  frag2,
  fireVert,
  fireFrag,
  toonVert,
  toonFrag
} from '../../../../assets/shader/shader1';

import rocket from '../../../../assets/model/rocket/editedRocketWithFlame.glb';

import {
  SoftSkillsWrap,
  Skillrow,
  SkillP,
  SkillCanvas,
  SkillrowSection,
  SkillTitle
} from '../soft-skills.style';

import { FaRocket } from 'react-icons/fa';
const primaryColor = props => props.theme.primary;

//............ global...................................
let renderer = null;

//...........observer stuff....................................................................
let initialPixelRatio = null;
let intersectionRatio = 0;
const observer = new IntersectionObserver(
  ([entry]) => {
    intersectionRatio = entry.intersectionRatio;
    renderer.setPixelRatio(initialPixelRatio * intersectionRatio);
  },
  {
    threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]
  }
);

const Rocket = () => {
  // list referenced elements
  const mountRef = useRef(null);
  const canvasParent = useRef(null);

  useEffect(() => {
    let clock = new THREE.Clock();
    let mountRefCurrent = mountRef.current;

    let rendererXSize, rendererYSize;

    // function to return orientation of device
    const aspectRatio = () => {
      let width = window.innerWidth;
      let height = window.innerHeight;

      if (width > height) {
        return 'landscape';
      }
      return 'portrait';
    };

    // update renderer width and height
    const resizeScreen = () => {
      if (window.innerWidth <= 768) {
        rendererXSize =
          window.innerWidth - (window.innerWidth - mountRefCurrent.clientWidth);

        if (window.innerWidth >= 480) {
          rendererYSize = window.innerHeight * 0.5;
        } else {
          rendererYSize = window.innerHeight * 0.5;
          rendererXSize =
            window.innerWidth -
            (window.innerWidth - mountRefCurrent.clientWidth) * 0.5;
        }
      } else {
        rendererXSize = canvasParent.current.clientWidth / 2;
        if (aspectRatio() === 'landscape') {
          rendererYSize = window.innerHeight * 0.5;
        } else if (aspectRatio() === 'portrait') {
          rendererYSize = rendererXSize;
        }
      }
    };

    observer.observe(mountRef.current);

    resizeScreen();

    // ? MODEL START (scene, camera, renderer) -----------------------------------
    let scene = new THREE.Scene();
    let camera = new THREE.PerspectiveCamera(
      23,
      rendererXSize / rendererYSize,
      0.1,
      1000
    );
    renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    renderer.setPixelRatio(window.devicePixelRatio);
    initialPixelRatio = window.devicePixelRatio;
    renderer.setClearColor('#abc0bb');
    renderer.setSize(rendererXSize, rendererYSize);

    // attach the renderer to the canvas
    mountRefCurrent.appendChild(renderer.domElement);

    // * add custom 3d object (rocket) -----------------
    let mixer = null;

    const speedlinecolor = new THREE.Vector3(0, 0, 0);

    var speedlinemat = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: speedlinecolor }
      },
      vertexShader: vert2,
      fragmentShader: frag2
    });

    // const rocketColor = new THREE.Vector3(1, 1, 1);
    const lightPos = new THREE.Vector3(3, 9, -7);
    const darkColor = 0.3;
    const midColor = 0.5;

    let toonMaterial = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Vector3(1, 1, 1) },
        lightPosition: { value: lightPos },
        brightColor: { value: 0.9 },
        darkColor: { value: darkColor },
        midColor: { value: midColor }
      },
      vertexShader: toonVert,
      fragmentShader: toonFrag
    });

    let rocketRedMat = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Vector3(1.0, 0.2, 0.0) },
        lightPosition: { value: lightPos },
        brightColor: { value: 0.9 },
        darkColor: { value: darkColor },
        midColor: { value: midColor }
      },
      vertexShader: toonVert,
      fragmentShader: toonFrag
    });

    let rocketChromeBit = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Vector3(0.4, 0.4, 0.4) },
        lightPosition: { value: lightPos },
        brightColor: { value: 0.9 },
        darkColor: { value: darkColor },
        midColor: { value: midColor }
      },
      vertexShader: toonVert,
      fragmentShader: toonFrag
    });

    let rocketGlass = new THREE.ShaderMaterial({
      uniforms: {
        color: { value: new THREE.Vector3(0.0, 1.0, 0.8) },
        lightPosition: { value: lightPos },
        brightColor: { value: 0.9 },
        darkColor: { value: darkColor },
        midColor: { value: midColor }
      },
      vertexShader: toonVert,
      fragmentShader: toonFrag
    });

    let fireMat = new THREE.ShaderMaterial({
      uniforms: {
        lightPosition: { value: lightPos },
        step1: { value: 0.8 },
        step2: { value: 0.96 },
        outerColor: { value: new THREE.Vector3(0.88235, 1.0, 0.0) },
        middleColor: { value: new THREE.Vector3(0.9137, 0.35294, 0.16862) },
        innerColor: { value: new THREE.Vector3(1.0, 0.78431, 0.0) }
      },
      vertexShader: fireVert,
      fragmentShader: fireFrag
    });

    const loader = new GLTFLoader();
    loader.load(
      rocket,
      gltf => {
        let model = gltf.scene;

        // set the rocket material to toon shader
        gltf.scene.children[5].material = rocketRedMat;
        gltf.scene.children[6].material = rocketChromeBit;
        gltf.scene.children[7].material = rocketGlass;
        gltf.scene.children[8].material = toonMaterial;
        gltf.scene.children[9].material = fireMat;

        for (let i = 1; i < 5; i++) {
          gltf.scene.children[i].material = speedlinemat;
        }
        mixer = new THREE.AnimationMixer(model);

        let actions = [
          'action1',
          'action2',
          'action3',
          'action4',
          'action5',
          'action6',
          'action7',
          'action8',
          'action9',
          'action10'
        ];

        actions.map((action, index) => {
          return (
            (action = mixer.clipAction(gltf.animations[index])), action.play()
          );
        });

        scene.add(gltf.scene);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    camera.position.set(-1.5, 2.5, -25);
    camera.rotation.y = Math.PI;
    scene.rotation.z = Math.PI / 5;

    let dirLight = new THREE.DirectionalLight(0xffebad, 5);
    dirLight.position.set(18, 21, -15);
    dirLight.castShadow = true;
    scene.add(dirLight);

    let ambientLight = new THREE.AmbientLight(0x8d7c4c, 2);
    scene.add(ambientLight);
    // ? ASSET 01 END (scene, camera, renderer) -----------------------------------------

    // * WINDOW RESIZE EVENT LISTENER----------------------------------------------------
    // const onWindowResize = () => {
    //   resizeScreen();
    //   camera.aspect = rendererXSize / rendererYSize;
    //   camera.updateProjectionMatrix();
    //   renderer.setSize(rendererXSize, rendererYSize);
    // };

    // add event listener to window
    // window.addEventListener('resize', onWindowResize, false);

    // function to animate 3D asset in real time
    const render = () => {
      requestAnimationFrame(render);
      let dt = clock.getDelta();

      if (mixer != null) {
        mixer.update(dt);
      }
      renderer.render(scene, camera);
    };

    // call render func
    render();

    return () => {
      // remove the renderer from the canvas
      mountRefCurrent.removeChild(renderer.domElement);
    };
  }, []);

  return (
    <SoftSkillsWrap rowBgColor={primaryColor} rowColor="#222">
      <Skillrow ref={canvasParent}>
        <SkillrowSection margRight>
          <SkillTitle>
            Optimisation &nbsp;
            <FaRocket />
          </SkillTitle>
          <SkillP>
            I write clean and optimized code, applying several patterns to
            implement gameplay mechanics and data structures as efficiently as
            possible.
          </SkillP>
        </SkillrowSection>
        <SkillrowSection borderRadius>
          <SkillCanvas ref={mountRef}></SkillCanvas>
        </SkillrowSection>
      </Skillrow>
    </SoftSkillsWrap>
  );
};

export default Rocket;
