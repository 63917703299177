import React, { useEffect, useRef } from 'react';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

import {
  procPlanetVert,
  proPlanetFrag
} from '../../../../assets/shader/shader1';

import planet from '../../../../assets/model/planet/planetSphere3x2.glb';

import {
  SoftSkillsWrap,
  Skillrow,
  SkillP,
  SkillCanvas,
  SkillrowSection,
  SkillTitle
} from '../soft-skills.style';
import { FaGlobe } from 'react-icons/fa';
import { lerp } from 'three/src/math/MathUtils';

function selectRandomFromSet(value) {
  let returnedVal = value[Math.floor(Math.random() * value.length)];
  return returnedVal;
}

function getRandomValue(minVal, maxVal) {
  return lerp(minVal, maxVal, Math.random());
}

const fresnelcolorSet = [
  new THREE.Vector3(0.8, 0.8, 0.63),
  new THREE.Vector3(1.0, 1.0, 0.70196),
  new THREE.Vector3(0.70196, 1.0, 0.85882),
  new THREE.Vector3(1.0, 0.67843, 0.67843),
  new THREE.Vector3(0.67843, 0.93725, 1.0),
  new THREE.Vector3(1.0, 0.82353, 0.67843),
  new THREE.Vector3(0.94902, 0.90196, 1.0)
];

const waterColorSet = [
  new THREE.Vector3(0.5, 0.9, 0.870588),
  new THREE.Vector3(0.8, 1.0, 0.8),
  new THREE.Vector3(0.0, 0.933, 1.0),
  new THREE.Vector3(0.98824, 0.87059, 0.88627),
  new THREE.Vector3(0.29412, 0.70588, 0.46667),
  new THREE.Vector3(0.87451, 0.81961, 0.72549)
];

const landBorderColorSet = [
  new THREE.Vector3(1, 1, 1),
  new THREE.Vector3(0.78, 0.78, 0.78),
  new THREE.Vector3(0.96, 0.9, 0.8),
  new THREE.Vector3(0.49412, 0.78824, 0.70588)
];

const lowLandColorSet = [
  new THREE.Vector3(0.5843137, 0.4039, 0.043137),
  new THREE.Vector3(0.56471, 0.63922, 0.0),
  new THREE.Vector3(0.61569, 0.20392, 0.20392),
  new THREE.Vector3(0.85098, 0.65098, 0.05098),
  new THREE.Vector3(0.27451, 0.48235, 0.17647)
];

const midLandColorSet = [
  new THREE.Vector3(0.48627, 0.32941, 0.36471),
  new THREE.Vector3(0.188235, 0.8705, 0.08235),
  new THREE.Vector3(0.66275, 0.73725, 0.12549),
  new THREE.Vector3(0.96078, 0.51765, 0.21961),
  new THREE.Vector3(0.72941, 0.61176, 0.41176)
];

const highLandColorSet = [
  new THREE.Vector3(0.8627, 1, 0.5882),
  new THREE.Vector3(1.0, 0.95294, 0.92157),
  new THREE.Vector3(0.83922, 1.0, 0.99608),
  new THREE.Vector3(0.78431, 0.99608, 0.83529),
  new THREE.Vector3(0.99608, 0.80392, 0.80392)
];

const Planet = () => {
  const mountRef = useRef(null);
  const canvasParent = useRef(null);

  const clock = useRef(null);

  const fresnelcolor = useRef(null);
  const waterColor = useRef(null);
  const landBorderColor = useRef(null);
  const lowLandColor = useRef(null);
  const midLandColor = useRef(null);
  const highLandColor = useRef(null);
  const offset = useRef(null);

  // target Global variables for planet
  const targetFresnelcolor = useRef(null);
  const targetWaterColor = useRef(null);
  const targetLandBorderColor = useRef(null);
  const targetLowLandColor = useRef(null);
  const targetMidLandColor = useRef(null);
  const targetHighLandColor = useRef(null);
  const targetOffset = useRef(null);

  // global variables
  const renderer = useRef(null);
  const camera = useRef(null);
  const scene = useRef(null);
  const controls = useRef(null);
  const planetObj = useRef(null);

  // animation states
  const useAnimateLoop = useRef(true);
  const lerping = useRef(false);
  const lerpTime = useRef(0.0);
  const totalLerpTime = 3.0;
  const frameCount = useRef(-1);
  const frameRate = useRef(0.0);
  const firstPerformanceTestInitiated = useRef(false);
  const firstPerformanceTestRecieved = useRef(false);
  const testTrials = useRef(0);

  useEffect(() => {
    const lerpPlanetValues = () => {
      let lerpval = 0.05;
      // planet colours
      fresnelcolor.current.lerp(targetFresnelcolor.current, lerpval);
      waterColor.current.lerp(targetWaterColor.current, lerpval);
      landBorderColor.current.lerp(targetLandBorderColor.current, lerpval);
      lowLandColor.current.lerp(targetLowLandColor.current, lerpval);
      midLandColor.current.lerp(targetMidLandColor.current, lerpval);
      highLandColor.current.lerp(targetHighLandColor.current, lerpval);
      offset.current.lerp(targetOffset.current, lerpval);
    };

    const getTargetPlanetValues = () => {
      // planet colours
      targetFresnelcolor.current = selectRandomFromSet(fresnelcolorSet);
      targetWaterColor.current = selectRandomFromSet(waterColorSet);
      targetLandBorderColor.current = selectRandomFromSet(landBorderColorSet);
      targetLowLandColor.current = selectRandomFromSet(lowLandColorSet);
      targetMidLandColor.current = selectRandomFromSet(midLandColorSet);
      targetHighLandColor.current = selectRandomFromSet(highLandColorSet);
      targetOffset.current = new THREE.Vector3(
        getRandomValue(-2, 2),
        getRandomValue(-2, 2),
        getRandomValue(-2, 2)
      );
    };

    const render = () => {
      let dt = clock.current.getDelta();

      if (lerping.current) {
        lerpPlanetValues();
        lerpTime.current += dt;
        frameCount.current += 1; //placed here because the first lerpTime is zero due to setting the oldTime in changePlanetValues()
        if (lerpTime.current >= totalLerpTime) {
          useAnimateLoop.current = false;
          lerping.current = false;

          //....... adjust quality based on speed ........................
          if (testTrials.current <= 1) {
            frameRate.current = frameCount.current / lerpTime.current;
            if (frameRate.current <= 15) renderer.current.setPixelRatio(0.5);
            else if (frameRate.current <= 30)
              renderer.current.setPixelRatio(0.7);
            else if (frameRate.current <= 60)
              renderer.current.setPixelRatio(1.0);
            else renderer.current.setPixelRatio(window.devicePixelRatio);
            firstPerformanceTestRecieved.current = true;
          }

          frameCount.current = -1;
        }
      }

      if (useAnimateLoop.current) {
        requestAnimationFrame(render);
      }
      if (planetObj.current !== undefined && !lerping.current) {
        useAnimateLoop.current = false;
        // run test to determine if it is a low performing device
        if (!firstPerformanceTestInitiated.current) {
          if (testTrials.current > 0)
            firstPerformanceTestInitiated.current = true;
          testTrials.current += 1;

          changePlanetValues();
        }
      }

      renderer.current.render(scene.current, camera.current);
    };

    const changePlanetValues = () => {
      getTargetPlanetValues();
      lerping.current = true;
      lerpTime.current = 0.0;
      clock.current.getDelta(); // reset last time stored on three.js clock
      if (!useAnimateLoop.current) {
        useAnimateLoop.current = true;
        render();
      }
    };

    const runRender = () => {
      if (!useAnimateLoop.current) render();
    };

    function setMaterialValues() {
      let planetMat = new THREE.ShaderMaterial({
        uniforms: {
          normalintensity: { value: 1.0 },
          lightPosition: { value: new THREE.Vector3(-30.0, 3.0, -15.0) },
          lightintensity: { value: 0.5 },
          kecolor: { value: fresnelcolor.current },
          start: { value: 0.5 },
          end: { value: 1.0 },
          kealpha: { value: 2.0 },
          waterColor: { value: waterColor.current },
          landBorderColor: { value: landBorderColor.current },
          lowLandColor: { value: lowLandColor.current },
          midLandColor: { value: midLandColor.current },
          highLandColor: { value: highLandColor.current },
          offset: { value: offset.current }
        },
        vertexShader: procPlanetVert,
        fragmentShader: proPlanetFrag
      });

      return planetMat;
    }

    const mountRefCurrent = mountRef.current;

    clock.current = new THREE.Clock();
    mountRefCurrent.addEventListener('click', changePlanetValues);

    let rendererXSize, rendererYSize;

    // function to return orientation of device
    const aspectRatio = () => {
      let width = window.innerWidth;
      let height = window.innerHeight;

      if (width > height) {
        return 'landscape';
      }
      return 'portrait';
    };

    // update renderer width and height
    const resizeScreen = () => {
      if (window.innerWidth <= 768) {
        rendererXSize =
          window.innerWidth - (window.innerWidth - mountRefCurrent.clientWidth);

        if (window.innerWidth >= 480) {
          rendererYSize = window.innerHeight * 0.5;
        } else {
          rendererYSize = window.innerHeight * 0.5;
          rendererXSize =
            window.innerWidth -
            (window.innerWidth - mountRefCurrent.clientWidth) * 0.5;
        }
      } else {
        rendererXSize = canvasParent.current.clientWidth / 2;
        if (aspectRatio() === 'landscape') {
          rendererYSize = window.innerHeight * 0.5;
        } else if (aspectRatio() === 'portrait') {
          rendererYSize = rendererXSize;
        }
      }
    };
    // const onWindowResize = () => {
    //   resizeScreen();
    //   camera.current.aspect = rendererXSize / rendererYSize;
    //   camera.current.updateProjectionMatrix();
    //   renderer.current.setSize(rendererXSize, rendererYSize);
    //   runRender();
    // };
    resizeScreen();

    // ? MODEL START (scene, camera, renderer) ------------------------------------
    scene.current = new THREE.Scene();
    camera.current = new THREE.PerspectiveCamera(
      50,
      rendererXSize / rendererYSize,
      0.1,
      1000
    );

    renderer.current = new THREE.WebGLRenderer({ antialias: true });
    renderer.current.setPixelRatio(window.devicePixelRatio);
    renderer.current.setClearColor('#111');
    renderer.current.setSize(rendererXSize, rendererYSize);

    // attach the renderer to the canvas
    mountRefCurrent.appendChild(renderer.current.domElement);

    // planet colours
    fresnelcolor.current = selectRandomFromSet(fresnelcolorSet);
    waterColor.current = selectRandomFromSet(waterColorSet);
    landBorderColor.current = selectRandomFromSet(landBorderColorSet);
    lowLandColor.current = selectRandomFromSet(lowLandColorSet);
    midLandColor.current = selectRandomFromSet(midLandColorSet);
    highLandColor.current = selectRandomFromSet(highLandColorSet);
    offset.current = new THREE.Vector3(
      getRandomValue(-2, 2),
      getRandomValue(-2, 2),
      getRandomValue(-2, 2)
    );

    let materialForThePlanet = setMaterialValues();

    materialForThePlanet.extensions.derivatives = true;

    camera.current.position.set = (0, 0, 15);

    controls.current = new OrbitControls(
      camera.current,
      renderer.current.domElement
    );
    controls.current.addEventListener('change', runRender);

    const loader = new GLTFLoader();
    loader.load(
      planet,
      gltf => {
        planetObj.current = gltf.scene.children[0];
        planetObj.current.position.set(0, 0, -5);

        planetObj.current.material = materialForThePlanet;

        controls.current.target.copy(planetObj.current.position);
        controls.current.enableZoom = false;
        controls.current.enablePan = false;

        scene.current.add(gltf.scene);
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    // * WINDOW RESIZE EVENT LISTENER----------------------------------------------------
    camera.current.aspect = rendererXSize / rendererYSize;
    camera.current.updateProjectionMatrix();

    // add event listener to window
    // window.addEventListener('resize', onWindowResize, false);

    // call render func
    render();

    return () => {
      // remove the renderer from the canvas
      mountRefCurrent.removeChild(renderer.current.domElement);
    };
  }, []);

  return (
    <SoftSkillsWrap rowBgColor="#111111" rowColor="#f0f0f0">
      <Skillrow ref={canvasParent} flip>
        <SkillrowSection margLeft>
          <SkillTitle>
            Visuals &nbsp;
            <FaGlobe />
          </SkillTitle>
          <SkillP>
            Making a good looking game is very important to me. I use image
            manipulation, shaders and 3D modelling to achieve immersive visuals.
          </SkillP>
        </SkillrowSection>
        <SkillrowSection borderRadius>
          <SkillCanvas ref={mountRef}></SkillCanvas>
        </SkillrowSection>
      </Skillrow>
    </SoftSkillsWrap>
  );
};

export default Planet;
